import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationData } from '../../../models/Feed.model';
import { RootState } from '../../store';
import {
  useGetNotificationListMutation,
  useUpdateNotificationMutation,
} from '../../../services/feed';
import { useGetTierRewardsMutation } from '../../../services/membershipTiers';
import { setRevalidateData } from '../../../screens/Feed/slices/notificationSlice';
import BATAnnouncementToast from './BATAnnouncementToast';
import BATAnnouncementSticky from './BATAnnouncementSticky';
import BATAnnouncementPopup from './BATAnnouncementPopup';

const BATAnnouncements = () => {
  const ageVerified = useSelector((state: RootState) => state?.auth?.userData?.ageVerified);
  const revalidateData = useSelector((state: RootState) => state.notification.revalidateData);

  const [toasts, setToasts] = useState<NotificationData[]>([]);
  const [sticky, setSticky] = useState<NotificationData[]>([]);
  const [popups, setPopups] = useState<NotificationData[]>([]);

  const [updateNotification] = useUpdateNotificationMutation();
  const [getNotificationList] = useGetNotificationListMutation();
  const [getTierRewards] = useGetTierRewardsMutation();

  const dispatch = useDispatch();

  const unreadNotifications = (notifications: NotificationData[]) => {
    const unreadNotifications = notifications.filter((notification) => !notification.status);
    return unreadNotifications;
  };

  const getAndSetNotificationList = (handleFinally?: () => void) => {
    getTierRewards().unwrap();
    getNotificationList({})
      .unwrap()
      .then((data) => {
        if (!Array.isArray(data.toasts)) {
          const unreadToasts = unreadNotifications(data.toasts.data);
          setToasts(unreadToasts);
        }
        if (!Array.isArray(data.sticky)) {
          const unreadSticky = unreadNotifications(data.sticky.data);
          setSticky(unreadSticky);
        }
        if (!Array.isArray(data['pop-ups'])) {
          const unreadPopups = unreadNotifications(data['pop-ups'].data);
          setPopups(unreadPopups);
        }
      })
      .finally(() => {
        handleFinally && handleFinally();
      });
  };

  useEffect(() => {
    if (ageVerified === 1) {
      setTimeout(() => {
        getAndSetNotificationList();
      }, 10000);
    }
  }, [ageVerified]);

  useEffect(() => {
    if (revalidateData) {
      getAndSetNotificationList(() => {
        dispatch(setRevalidateData(false));
      });
    }
  }, [revalidateData]);

  const handleSeenNotification = (id: string) => {
    updateNotification({ status: 'view', nid: id }).unwrap();
  };

  return (
    <>
      <BATAnnouncementToast
        handleSeenNotification={handleSeenNotification}
        notifications={toasts}
      />
      <BATAnnouncementSticky
        handleSeenNotification={handleSeenNotification}
        notifications={sticky}
      />
      <BATAnnouncementPopup
        handleSeenNotification={handleSeenNotification}
        notifications={popups}
      />
    </>
  );
};
export default BATAnnouncements;
