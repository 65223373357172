export enum EVENT_CATEGORY_FE {
  // +++
  INSERT_PACK_CODE = 'pack_codes',
  READING_ARTICLE = 'reading_articles',
  BONUS_CODE = 'bonus',
  STORY = 'interactive_story',
  SURVEY = 'completing_survey',
  DEVICE_REGISTER = 'device_registration',
  REFERRAL = 'referral',
  RATINGS = 'ratings',
  CAMPAIGNS = 'campaigns',
  PROFILE = 'profile',
  RETURN_POINTS = 'return_points',
  MEMBERSHIP_TIERS = 'membership_tiers',
  // ---
  REDEEMED_PRIZE = 'redeemed_points',
  INCREASE_CHANCES = 'consumed_points',
  EXPIRED_LOYALTY_POINTS = 'expired_points_loyalty',
  EXPIRED_ACTIVITY_POINTS = 'expired_points_activity',
  NOTIFICATION = 'notification',
}

export const EVENT_CATEGORY_INCREASE = {
  [EVENT_CATEGORY_FE.INSERT_PACK_CODE]: [
    'pack_codes_kt',
    'pack_codes_dh',
    'pack_codes_glo',
    'pack_codes_pm',
    'pack_codes_vuse',
    'pack_codes',
  ],
  [EVENT_CATEGORY_FE.READING_ARTICLE]: ['reading_articles', 'interactive_article'],
  [EVENT_CATEGORY_FE.BONUS_CODE]: [
    'welcome_bonus',
    'bonus_points_news_and_offers',
    'user_birthday',
    'brand_birthday',
    'bonus_points_offline_channels',
    'migration_bonus',
    'bf_subscribers',
    'luckybox_redeem',
    'orders_on_ecommerce',
    'subscription',
    'advent_redeem',
    'pack_codes_velo',
    'advent_redeem_order',
    'tier_reward_birthday_bonus_glo',
    'tier_reward_birthday_bonus_dh',
    'tier_reward_birthday_bonus_kt',
    'tier_reward_birthday_bonus_pm',
    'tier_reward_birthday_bonus_rm',
    'tier_reward_birthday_bonus_vuse',
    'tier_reward_birthday_bonus_velo',
  ],
  [EVENT_CATEGORY_FE.STORY]: ['interactive_story'],
  [EVENT_CATEGORY_FE.SURVEY]: [
    'completing_survey',
    'ncp_pm',
    'completing_product_quiz',
    'taste_profile_quizz',
  ],
  [EVENT_CATEGORY_FE.DEVICE_REGISTER]: ['device_registration', 'device_registration_vuse'],
  [EVENT_CATEGORY_FE.REFERRAL]: [
    'friend_referral_oneup_old',
    'friend_referral_glo_new_user',
    'friend_referral_oneup_new',
    'friend_referral_glo_old_user',
  ],
  [EVENT_CATEGORY_FE.RATINGS]: ['product_review'],
  [EVENT_CATEGORY_FE.CAMPAIGNS]: [
    'favourite_campaign_img',
    'approved_campaign_img',
    'top_campaign_img',
    'liked_campaign_img',
  ],
  [EVENT_CATEGORY_FE.PROFILE]: ['profile_completion'],
  [EVENT_CATEGORY_FE.RETURN_POINTS]: ['points_return'],
  [EVENT_CATEGORY_FE.MEMBERSHIP_TIERS]: ['tier_reward_monthly_bonus', 'tier_reward_one_time_bonus'],
  [EVENT_CATEGORY_FE.NOTIFICATION]: ['push_notification_bonus'],
};

export const EVENT_CATEGORY_DECREASE = {
  [EVENT_CATEGORY_FE.REDEEMED_PRIZE]: ['claiming'],
  [EVENT_CATEGORY_FE.INCREASE_CHANCES]: ['consumed_points'],
  [EVENT_CATEGORY_FE.EXPIRED_LOYALTY_POINTS]: ['expired_points_loyalty'],
  [EVENT_CATEGORY_FE.EXPIRED_ACTIVITY_POINTS]: ['expired_points_activity'],
};

export function valueExistsInDecrease(value: string): boolean {
  return Object.values(EVENT_CATEGORY_DECREASE).some((array) => array.includes(value));
}

export function valueExistsInIncrease(value: string): boolean {
  return Object.values(EVENT_CATEGORY_INCREASE).some((array) => array.includes(value));
}

export const getCategoryValueIfExists = (searchedValue: string): EVENT_CATEGORY_FE | null => {
  function getCategoryFromEnum(values: string[], searchedValue: string, category: string) {
    if (values.includes(searchedValue)) {
      return category;
    }
    return null;
  }

  if (valueExistsInDecrease(searchedValue)) {
    for (const [category, values] of Object.entries(EVENT_CATEGORY_DECREASE)) {
      const result = getCategoryFromEnum(values, searchedValue, category);
      if (result) {
        return result as EVENT_CATEGORY_FE;
      }
    }
  } else if (valueExistsInIncrease(searchedValue)) {
    for (const [category, values] of Object.entries(EVENT_CATEGORY_INCREASE)) {
      const result = getCategoryFromEnum(values, searchedValue, category);
      if (result) {
        return result as EVENT_CATEGORY_FE;
      }
    }
  }
  return null;
};

export const defineIconByEventType = (eventType: EVENT_CATEGORY_FE | null) => {
  switch (eventType) {
    case EVENT_CATEGORY_FE.INSERT_PACK_CODE:
      return 'backpack';
    case EVENT_CATEGORY_FE.READING_ARTICLE:
      return 'text-snippet';
    case EVENT_CATEGORY_FE.BONUS_CODE:
      return 'card-giftcard';
    case EVENT_CATEGORY_FE.STORY:
      return 'web_stories';
    case EVENT_CATEGORY_FE.SURVEY:
      return 'checklist';
    case EVENT_CATEGORY_FE.DEVICE_REGISTER:
      return 'plus-circle';
    case EVENT_CATEGORY_FE.REDEEMED_PRIZE:
      return 'shopping_cart';
    case EVENT_CATEGORY_FE.INCREASE_CHANCES:
      return 'star-rate';
    case EVENT_CATEGORY_FE.EXPIRED_LOYALTY_POINTS:
      return 'mini-wallet';
    case EVENT_CATEGORY_FE.EXPIRED_ACTIVITY_POINTS:
      return 'mini-wallet';
    case EVENT_CATEGORY_FE.REFERRAL:
      return 'person_add_alt_1';
    case EVENT_CATEGORY_FE.RATINGS:
      return 'thumb_up_alt';
    case EVENT_CATEGORY_FE.CAMPAIGNS:
      return 'rocket-2';
    case EVENT_CATEGORY_FE.PROFILE:
      return 'how_to_reg';
    case EVENT_CATEGORY_FE.RETURN_POINTS:
      return 'refresh';
    case EVENT_CATEGORY_FE.MEMBERSHIP_TIERS:
      return 'bar_chart';
    case EVENT_CATEGORY_FE.NOTIFICATION:
      return 'notification';

    default:
      return 'fiber_smart_record';
  }
};

export const SpecialEvents = [
  'points_return',
  'tier_reward_birthday_bonus_glo',
  'tier_reward_birthday_bonus_dh',
  'tier_reward_birthday_bonus_kt',
  'tier_reward_birthday_bonus_pm',
  'tier_reward_birthday_bonus_rm',
  'tier_reward_birthday_bonus_vuse',
  'tier_reward_birthday_bonus_velo',
  'tier_reward_monthly_bonus',
  'tier_reward_one_time_bonus',
];
