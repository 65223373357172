export default {
  primary: '#5C53D7',
  secondary: '#977CFF',
  active: '#232052',
  background: '#0F1025',
  placeholder: '#7B7EA9',
  subtitle: '#878792',
  text: '#ffffff',
  inactiveText: '#B7B7BE80',
  inactiveBorderColor: '#b7b7be29',
  darkText: '#0F1025',
  label: '#F9F9FA',
  warningMessageText: '#8F93A0',
  cardsBackground: '#22243E',
  modalBackground: '#1A1C32',
  expiredText: '#E8431A',
  toastError: '#FF0100',
  toastAnnounce: '#6C6C83',
  inputText: '#8494C3',
  toolTipBackground: '#28293C',
  inviteFriendBackground: '#363963',
  quizLabel: '#3E6CED',
  deviceRegisterLabel: '#9d8bc2',
  recommendLabel: '#1AB7E8',
  datesColor: '#ffffffcc',
  blueLight: '#BFB4FA',
  blueDark: '#323352',
  white: '#FFFFFF',
  white_opacity_95: '#fffffff2',
  white_50: '#FCFCFD',
  violet: '#867FE1',
  red_100: '#cf6679',
  black: '#000000',
  grey_title: '#F9FAFA',
  grey_subtitle: '#89899C',
  grey_text: '#A2A2B1',
  card_border_lighter: '#323352',
  card_background_lighter: '#24243B',
  dark_accent: '#665DD9',
  successBackgroundWeak: '#092D00',
  successIcon: '#20A100',
  warningBackgroundWeak: '#261B00',
  warningIcon: '#FFC530',
  errorBackgroundWeak: '#390B0C',
  errorIcon: '#F35354',
  backgroundMedium: '#4A4B68',
  icon: '#E1E2E6',
  shadowColor: '#000000',
  surface_weakest: '#1B1B2C',
  danger: '#F55859',
  warningBorderWeak: '#644900',
  dangerBorderWeaker: '#6B1516',
  success: '#30BC0C',
  successBgWeak: '#072100',
  accentSurfaceWakest: '#1A183E',
  tealBg: '#127B75',
  tealIcon: '#35B4AD',
  purpleBg: '#974AC7',
  midnightShadow: '#13131F',
};
