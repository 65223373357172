import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import {
  Brand,
  BundleVariation,
  GuaranteedPrizes,
  LimitedPrizes,
  LuckyMoment,
  LuckyMoments,
  Prize,
} from '../models/Prize.model';

export const prizesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPrize: builder.mutation<Prize[], any>({
      query: ({ type, prizeSlug }) => ({
        url: `api/${type}/details/${prizeSlug}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getLimitedPrizes: builder.mutation<LimitedPrizes[], any>({
      query: ({ prizesType, tid }) => ({
        url: `api/collection/${prizesType}/${tid}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getComingSoonLimitedPrizes: builder.mutation<LimitedPrizes[], any>({
      query: ({ prizesType, tid }) => ({
        url: `api/collection/${prizesType}/${tid}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getGuaranteedPrizes: builder.mutation<GuaranteedPrizes[], any>({
      query: ({ prizesType, tid, categoryId }) => ({
        url: `api/collection/${prizesType}/${tid}${categoryId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getLuckyMoments: builder.mutation<LuckyMoments[], any>({
      query: ({ tid, status }) => ({
        url: `${Endpoints.getLuckyMoments}/${tid}/${status}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getLuckyMoment: builder.mutation<LuckyMoment, any>({
      query: ({ prizeSlug }) => ({
        url: `${Endpoints.getLuckyMoments}/details/${prizeSlug}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    consumeChance: builder.mutation<any, any>({
      query: ({ prizeSlug, chances }) => ({
        url: `api/${prizeSlug}/chance/consume/${chances}`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    consumeDigitalPrize: builder.mutation<any, any>({
      query: ({ prizeSlug, captchaData }) => ({
        url: `api/${prizeSlug}/digital-prize/consume`,
        method: 'POST',
        body: captchaData,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    consumePhysicalPrize: builder.mutation<any, any>({
      query: ({ body, prizeSlug }) => ({
        url: `api/${prizeSlug}/digital-prize/consume?check=true`,
        method: 'POST',
        body,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    redeemPhysicalPrize: builder.mutation<any, any>({
      query: ({ body, prizeSlug }) => ({
        url: `api/${prizeSlug}/digital-prize/consume`,
        method: 'POST',
        body,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getBundleVariations: builder.mutation<BundleVariation[], string[]>({
      query: (ids) => ({
        url: `api/taxonomy/bundle-variation/${ids.join('+')}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getBrands: builder.mutation<Brand[], any>({
      query: () => ({
        url: Endpoints.getBrands,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPrizeMutation,
  useGetLimitedPrizesMutation,
  useGetComingSoonLimitedPrizesMutation,
  useGetGuaranteedPrizesMutation,
  useGetLuckyMomentsMutation,
  useGetLuckyMomentMutation,
  useConsumeChanceMutation,
  useConsumeDigitalPrizeMutation,
  useConsumePhysicalPrizeMutation,
  useRedeemPhysicalPrizeMutation,
  useGetBundleVariationsMutation,
  useGetBrandsMutation,
} = prizesApi;
