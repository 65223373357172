import { Image, View } from 'react-native';
import { useSelector } from 'react-redux';
import LinearGradient from 'react-native-linear-gradient';

import { RootState } from '../../../src/store';
import { tiersPersonalizations } from '../data';
import { removeHtmlTags } from '../../../utils/stringUtils';
import BATText from '../../../src/components/common/BATText';
import BATDialog from '../../../src/components/common/BATDialog';
import BATButtonNew from '../../../src/components/common/BATButtonNew';
import colors from '../../../config/theme/shared/colors';

interface NewTierModalProps {
  title: string;
  body: string;
  button: {
    text: string;
    handleOnPress: () => void;
  };
  tierId: number;
  isOpen: boolean;
  handleClose: () => void;
}

export const NewTierModal = ({
  title,
  body,
  button,
  tierId,
  isOpen,
  handleClose,
}: NewTierModalProps) => {
  const tierRewards = useSelector((state: RootState) => state.tiers.tierRewards);

  const tierReward = tierRewards.find(({ tier_id }) => tier_id == tierId)!;
  const tierPersonalizations = tiersPersonalizations.find(
    ({ name }) => name === tierReward.tier_name,
  )!;

  return (
    <BATDialog
      visible={isOpen}
      onDismiss={handleClose}
      bodyStyles={{ padding: 0 }}
      customActionsStyles={{
        padding: 0,
      }}
      body={
        <View style={{ position: 'relative' }}>
          <View
            style={{
              position: 'absolute',
              top: -40,
              right: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 0,
            }}>
            <Image
              source={tierPersonalizations.personalization}
              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            />
          </View>

          <LinearGradient
            style={{
              position: 'absolute',
              top: -40,
              right: 0,
              height: '100%',
              width: '100%',
              zIndex: 1,
            }}
            useAngle
            angle={16}
            locations={[0.6, 1]}
            colors={['transparent', `${tierReward.tier_colour}40`]}
          />

          <View
            style={{
              position: 'relative',
              alignItems: 'center',
              padding: 16,
              paddingTop: 0,
              zIndex: 2,
            }}>
            <View
              style={{
                position: 'absolute',
                top: -20,
                left: 0,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: 70,
              }}>
              <Stars />
            </View>

            <View
              style={{
                position: 'relative',
                height: 72,
                width: 72,
                borderRadius: 50,
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderColor: tierReward.tier_colour,
                backgroundColor: tierReward.tier_colour,
                zIndex: 0,
              }}>
              <LinearGradient
                useAngle
                angle={200}
                colors={[`${tierReward.tier_colour}89`, tierReward.tier_colour]}
                locations={[0.1, 0.7]}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: 70,
                  width: 70,
                  borderRadius: 50,
                  backgroundColor: `${colors.shadowColor}B3`,
                }}
              />
              <Image
                source={tierPersonalizations.icon}
                style={{
                  height: 40,
                  width: 40,
                  marginTop: 6,
                  zIndex: 2,
                }}
              />
            </View>

            <View style={{ alignItems: 'center', marginTop: 24, rowGap: 8 }}>
              <BATText
                type="heading-3"
                color={colors.grey_title}
                customStyle={{ textAlign: 'center' }}>
                {title}
              </BATText>

              <BATText customStyle={{ textAlign: 'center' }}>{removeHtmlTags(body)}</BATText>
            </View>

            <BATButtonNew
              variant="contained"
              handleSubmitButton={button.handleOnPress}
              customStyle={{ width: '100%', marginTop: 32 }}>
              {button.text}
            </BATButtonNew>
          </View>
        </View>
      }
    />
  );
};

const Stars = () => {
  const stars: Record<string, number>[] = [
    { width: 4, height: 4, opacity: 30, bottom: 24, left: 4 },
    { width: 1, height: 1, opacity: 30, top: 24, left: 14 },
    { width: 2, height: 2, opacity: 99, top: 10, left: 35 },
    { width: 2, height: 2, opacity: 99, top: 18, right: 3 },
    { width: 4, height: 4, opacity: 30, top: 18, right: 15 },
    { width: 1, height: 1, opacity: 99, top: 35, right: 14 },
    { width: 2, height: 2, opacity: 30, top: 50, right: 0 },
  ];
  return (
    <View
      style={{
        height: 70,
        width: 100,
      }}>
      {stars.map((star, index) => (
        <View
          key={index}
          style={[
            {
              position: 'absolute',
              backgroundColor: `#ffffff${star.opacity}`,
              borderRadius: 50,
            },
            star,
          ]}
        />
      ))}
    </View>
  );
};
