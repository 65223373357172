import { Image, ImageStyle } from 'react-native';

import { BadgeStates } from '../models';

export enum BadgeIcons {
  Books = 'books',
  Briefcase = 'briefcase',
  Bulb = 'bulb',
  Camera = 'camera',
  Clover = 'clover',
  Crown = 'crown',
  Cup = 'cup',
  Diamond = 'diamond',
  Discount = 'discount',
  Horseshoe = 'horseshoe',
  Magnifier = 'magnifier',
  Medals = 'medals',
  Megaphone = 'megaphone',
  MoneyBox = 'money-box',
  Moon = 'moon',
  Nonstop = 'nonstop',
  Puzzle = 'puzzle',
  ShoppingCart = 'shopping-cart',
  Smartphone = 'smartphone',
  Target = 'target',
  Tickets = 'tickets',
  Sun = 'sun',
  Armor = 'armor',
  TreasureChest = 'treasure-chest',
  MountainPeak = 'mountain-peak',
  Heart = 'heart',
  CrossedFingers = 'crossed-fingers',
  Hourglass = 'hourglass',
  Stopwatch = 'stopwatch',
  Goblet = 'goblet',
  Message = 'message',
  Percentage = 'percentage',
  Ribbon = 'ribbon',
  Shield = 'shield',
  Torch = 'torch',
}

export const badgeIcons: Record<BadgeIcons, string> = {
  books: require('../../../assets/images/membership-tiers/badges/icons/books.svg'),
  briefcase: require('../../../assets/images/membership-tiers/badges/icons/briefcase.svg'),
  bulb: require('../../../assets/images/membership-tiers/badges/icons/bulb.svg'),
  camera: require('../../../assets/images/membership-tiers/badges/icons/camera.svg'),
  clover: require('../../../assets/images/membership-tiers/badges/icons/clover.svg'),
  crown: require('../../../assets/images/membership-tiers/badges/icons/crown.svg'),
  cup: require('../../../assets/images/membership-tiers/badges/icons/cup.svg'),
  diamond: require('../../../assets/images/membership-tiers/badges/icons/diamond.svg'),
  discount: require('../../../assets/images/membership-tiers/badges/icons/discount.svg'),
  horseshoe: require('../../../assets/images/membership-tiers/badges/icons/horseshoe.svg'),
  magnifier: require('../../../assets/images/membership-tiers/badges/icons/magnifier.svg'),
  medals: require('../../../assets/images/membership-tiers/badges/icons/medals.svg'),
  megaphone: require('../../../assets/images/membership-tiers/badges/icons/megaphone.svg'),
  'money-box': require('../../../assets/images/membership-tiers/badges/icons/money-box.svg'),
  moon: require('../../../assets/images/membership-tiers/badges/icons/moon.svg'),
  nonstop: require('../../../assets/images/membership-tiers/badges/icons/nonstop.svg'),
  puzzle: require('../../../assets/images/membership-tiers/badges/icons/puzzle.svg'),
  'shopping-cart': require('../../../assets/images/membership-tiers/badges/icons/shopping-cart.svg'),
  smartphone: require('../../../assets/images/membership-tiers/badges/icons/smartphone.svg'),
  target: require('../../../assets/images/membership-tiers/badges/icons/target.svg'),
  tickets: require('../../../assets/images/membership-tiers/badges/icons/tickets.svg'),
  sun: require('../../../assets/images/membership-tiers/badges/icons/sun.svg'),
  armor: require('../../../assets/images/membership-tiers/badges/icons/armor.svg'),
  'treasure-chest': require('../../../assets/images/membership-tiers/badges/icons/treasure-chest.svg'),
  'mountain-peak': require('../../../assets/images/membership-tiers/badges/icons/mountain-peak.svg'),
  heart: require('../../../assets/images/membership-tiers/badges/icons/heart.svg'),
  'crossed-fingers': require('../../../assets/images/membership-tiers/badges/icons/crossed-fingers.svg'),
  hourglass: require('../../../assets/images/membership-tiers/badges/icons/hourglass.svg'),
  stopwatch: require('../../../assets/images/membership-tiers/badges/icons/stopwatch.svg'),
  goblet: require('../../../assets/images/membership-tiers/badges/icons/goblet.svg'),
  message: require('../../../assets/images/membership-tiers/badges/icons/message.svg'),
  percentage: require('../../../assets/images/membership-tiers/badges/icons/percentage.svg'),
  ribbon: require('../../../assets/images/membership-tiers/badges/icons/ribbon.svg'),
  shield: require('../../../assets/images/membership-tiers/badges/icons/shield.svg'),
  torch: require('../../../assets/images/membership-tiers/badges/icons/torch.svg'),
};

export interface BadgeIconProps {
  icon: BadgeIcons;
  state: BadgeStates;
  width: number;
  style?: ImageStyle;
}

const BadgeIcon = ({ icon, state, width, style }: BadgeIconProps) => (
  <Image
    source={badgeIcons[icon]}
    style={[
      {
        aspectRatio: 1,
        width,
      },
      state === BadgeStates.Started
        ? { filter: 'grayscale(100%)', opacity: 0.5 }
        : state === BadgeStates.NotStarted
        ? { filter: 'grayscale(100%)', opacity: 0.1 }
        : undefined,
      style,
    ]}
  />
);

export default BadgeIcon;
