import moment from 'moment';
import { Condition, TierReward, TiersWithCondition } from '../models';
import { UserDataModel } from '../../../models/User.model';
import { GuaranteedPrizes } from '../../../models/Prize.model';
import colors from '../../../config/theme/shared/colors';

type Status = 'past' | 'present' | 'future';

interface GuaranteedPrizesWithTierData extends GuaranteedPrizes {
  prizesAdvancedAccessRewardDays?: number;
  tierNames?: string[];
}

export const formatDataByUserTierRewards = (
  prizes: GuaranteedPrizes[],
  tierRewards: TierReward[],
  currentUser: UserDataModel | null,
): GuaranteedPrizesWithTierData[] => {
  if (!currentUser) {
    return prizes;
  }

  const userTierReward = tierRewards.find(
    ({ tier_id }) => tier_id === currentUser.membershipTier.tier_id,
  );

  if (!userTierReward) {
    return prizes;
  }

  const prizesAdvancedAccessReward = userTierReward.rewards.find(
    ({ reward_id }) => reward_id === 'prizes_advanced_access',
  );

  if (!prizesAdvancedAccessReward) {
    return prizes;
  }

  return prizes.map((prize) => {
    const { newDate, status } = definePrizeVisibilityInAdvanceDate(
      prize.field_start_date,
      prizesAdvancedAccessReward.value,
    );

    prize = {
      ...prize,
      field_start_date_tier: newDate,
      status,
      prizesAdvancedAccessRewardDays: +prizesAdvancedAccessReward.value,
      tierNames:
        +prizesAdvancedAccessReward.value === 0 || moment().isAfter(moment(newDate, 'YYYY-MM-DD'))
          ? []
          : [userTierReward.tier_name],
    } as GuaranteedPrizesWithTierData;

    return prize;
  });
};

export const definePrizeVisibilityInAdvanceDate = (startDate: string, subtractedDays: string) => {
  if (!startDate) {
    return {
      newDate: startDate,
      status: 'past',
    };
  }

  if (!subtractedDays) {
    return {
      newDate: startDate,
      status: undefined,
    };
  }

  const newDate = moment(startDate, 'YYYY-MM-DD')
    .subtract(subtractedDays, 'days')
    .format('YYYY-MM-DD');

  const today = moment().format('YYYY-MM-DD');
  let status: Status;

  if (newDate < today) {
    status = 'past';
  } else if (newDate > today) {
    status = 'future';
  } else {
    status = 'present';
  }

  return {
    newDate,
    status,
  };
};

export const getWarningState = (
  expirationDate: string,
  hasIncompleteCurrentTierRequirements: boolean,
) => {
  if (!hasIncompleteCurrentTierRequirements) {
    return { text: 'Pragul minim pentru a rămâne în ', state: 'success' };
  }

  const parsedExpiration = moment(expirationDate, 'YYYY-MM-DD', true);

  const formattedExpirationDate = parsedExpiration.format('DD MMM YYYY');

  const currentDate = moment();
  const differenceInDays = parsedExpiration.diff(currentDate, 'days') + 1;

  if (differenceInDays < 3) {
    return {
      text: `Mai ai până la ${formattedExpirationDate} pentru a îndeplini cerințele și a rămâne în nivelul `,
      state: 'danger',
    };
  } else if (differenceInDays < 7) {
    return {
      text: `Mai ai până la ${formattedExpirationDate} pentru a îndeplini cerințele și a rămâne în nivelul `,
      state: 'warning',
    };
  }

  return { text: 'Cerințe minime pentru a rămâne în nivelul ', state: 'default' };
};

export const filterRequirements = (requirements: Condition[]) => {
  return requirements.filter((requirement) => !requirement.existsInPreviousTier).length;
};

export const discountByPeriod = (
  currentTierIndex: number,
  tiers: TiersWithCondition[],
  untilTier: string,
  startDate: string,
  endDate: string,
  conditions: Condition[],
  tierResetDate: string,
  isPromotion: boolean,
) => {
  const today = moment();
  const start = moment(startDate, 'DD.MM.YYYY');
  const end = moment(endDate, 'DD.MM.YYYY');

  const resetDate = moment(tierResetDate, 'YYYY-MM-DD');
  const updatedTierDate = resetDate.clone().subtract(30, 'days');

  const untilTierIndex = tiers.findIndex((tier) => tier.tier_name === untilTier);

  const discountedConditions = conditions.map((condition) => {
    if ('volume_condition' === condition.condition_id) {
      return {
        ...condition,
        discountedValue: +condition.value,
        value: Math.floor(+condition.value * 2),
      };
    }
    return { ...condition };
  });

  if (currentTierIndex > untilTierIndex) {
    return { description: undefined, conditions };
  }

  if (currentTierIndex === untilTierIndex && !isPromotion) {
    if (updatedTierDate.isSameOrBefore(end) && resetDate.isAfter(end)) {
      return {
        description: `Beneficiezi de oferta de lansare și ai avansat în nivelul Silver. Îți reamintim că pentru a rămâne in nivelul Silver este nevoie să acumulezi minim 100 de puncte de loialitate pana pe data ${resetDate.format(
          'DD.MM.YYYY',
        )}.`,
        conditions: today.isSameOrBefore(end) && !isPromotion ? discountedConditions : conditions,
      };
    }
  }

  if (currentTierIndex > untilTierIndex || (currentTierIndex === untilTierIndex && isPromotion)) {
    return { description: undefined, conditions };
  }

  if (today.isBefore(start) || today.isAfter(end)) {
    return { description: undefined, conditions };
  }

  return {
    description: `În perioada ${start.format('DD.MM.YYYY')} - ${end.format(
      'DD.MM.YYYY',
    )}, necesarul punctelor de loialitate pentru a ${
      isPromotion ? 'promova' : 'te menține'
    } în ${untilTier} este redus la 50 (de la 100). Profită de oferta de lansare.`,
    conditions: discountedConditions,
  };
};

export const formatDays = (numberOfDays: number) => {
  if (numberOfDays === 1) {
    return '1 zi';
  } else if (numberOfDays >= 2 && numberOfDays <= 19) {
    return `${numberOfDays} zile`;
  } else {
    return `${numberOfDays} de zile`;
  }
};

export const formatDescription = (
  condition_id: string,
  resetDate: string,
  shortVersion?: boolean,
) => {
  const startDate = moment(resetDate).subtract(30, 'days').add(1);
  const diffInDays = moment().diff(startDate, 'days') + 1;

  const formattedUnit =
    condition_id === 'volume_condition'
      ? 'puncte de loialitate'
      : condition_id === 'content_engagement_condition'
      ? 'puncte de activitate'
      : condition_id === 'pack_codes_condition'
      ? 'coduri din pachete'
      : 'coduri din categorii noi';

  switch (condition_id) {
    case 'volume_condition':
      return {
        formattedLabel: shortVersion
          ? `în ultimele 180 de zile`
          : `Total puncte de loialitate obtinute in ultimele 180 de zile.`,
      };
    default:
      return {
        formattedLabel: shortVersion
          ? `adunate în ${formatDays(diffInDays)}`
          : `Total ${formattedUnit} obținute în ${
              diffInDays === 1 ? 'ultima zi' : `ultimele ${formatDays(diffInDays)}`
            }.`,
      };
  }
};

export const defineChipProgressWidth = (level: number, maxLevel: number) => {
  const numberOfCodes = level.toString().length + maxLevel.toString().length;
  switch (numberOfCodes) {
    case 4:
      return 72;
    case 5:
      return 79;
    case 6:
      return 86;
    default:
      return 66;
  }
};
export const defineColorByConditionId = (conditionId: string) => {
  switch (conditionId) {
    case 'volume_condition':
      return '#EE876D';
    case 'pack_codes_condition':
      return '#CD8AB2';
    case 'content_engagement_condition':
      return '#BAA066';
    case 'new_category_condition':
      return '#8AA8B2';
    default:
      return '#974AC7';
  }
};

export const getGetColorByWarningState = (state: string) => {
  return state === 'default'
    ? colors.grey_title
    : state === 'success'
    ? colors.grey_text
    : state === 'warning'
    ? colors.warningIcon
    : colors.danger;
};

export const userDontHavePermissionToViewByTier = (
  tiers: TierReward[],
  userTierId?: number,
  prizeTierId?: number,
) => {
  if (!userTierId || !prizeTierId) {
    return false;
  }

  const prizeTierIndex = tiers.findIndex(({ tier_id }) => prizeTierId === tier_id);
  const userTierIndex = tiers.findIndex(({ tier_id }) => userTierId === tier_id);

  if (prizeTierIndex > userTierIndex) {
    return true;
  }

  const slicedTiers = tiers.slice(prizeTierIndex);

  return !slicedTiers.find(({ tier_id }) => userTierId === tier_id);
};

export const getUpperTiers = (startTier: number, tiers: TierReward[]) => {
  const startTierIndex = tiers.findIndex(({ tier_id }) => startTier === tier_id);

  return tiers.slice(startTierIndex).map((tier) => tier.tier_name);
};

export const getMinWidthForDescription = (tierName: string) => {
  switch (tierName) {
    case 'Bronze':
      return 462;
    case 'Silver':
      return 453;
    case 'Gold':
      return 448;
    default:
      return 475;
  }
};
