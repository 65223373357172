import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { Dialog, Portal } from 'react-native-paper';
import { useDispatch } from 'react-redux';

import BATIcon from '../BATIcon';
import { closeConfetti } from '../../slices/confettiSlice';
import { useIsMobile } from '../../hooks/useIsMobile';
import { sharedStyles } from '../../../config/theme/shared.styles';
import { BATConfetti } from './BATConfetti';
import colors from '../../../config/theme/shared/colors';
import batDialogStyles from '../../../styles/bat-dialog.module.css';

interface CustomStylesWithBackground extends ViewStyle {
  backgroundImage?: string;
  backgroundRepeat?: string;
  backgroundSize?: string;
  backgroundPosition?: string;
}

interface BATDialogProps {
  header?: JSX.Element;
  body?: JSX.Element;
  footer?: JSX.Element;
  visible: boolean;
  onDismiss: () => void;
  bodyStyles?: StyleProp<CustomStylesWithBackground>;
  customStyles?: StyleProp<CustomStylesWithBackground>;
  customTitleStyles?: ViewStyle;
  customActionsStyles?: ViewStyle;
  showCloseButton?: boolean;
  dismissable?: boolean;
  showAsDrawerOnMobile?: boolean;
  closeIconStyles?: ViewStyle;
}

const BATDialog = ({
  header,
  body,
  bodyStyles,
  footer,
  visible,
  onDismiss,
  customStyles,
  customTitleStyles,
  customActionsStyles,
  showCloseButton = true,
  dismissable = true,
  showAsDrawerOnMobile = false,
  closeIconStyles,
}: BATDialogProps) => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();

  const handleOnDismiss = () => {
    dispatch(closeConfetti());
    dismissable && onDismiss();
  };

  return (
    <Portal>
      <Dialog
        visible={visible}
        style={[
          batDialogStyles,
          sharedStyles.borderRadius,
          styles.dialog,
          {
            maxWidth: isMobile ? (showAsDrawerOnMobile ? '100%' : '90%') : 480,
            alignSelf: showAsDrawerOnMobile && isMobile ? 'flex-end' : 'center',
            width: showAsDrawerOnMobile && '100%',
          },
          customStyles,
        ]}
        onDismiss={handleOnDismiss}
        dismissable={dismissable}
        theme={{ colors: { backdrop: 'rgba(0, 0, 0, 0.7)' } }}>
        {showCloseButton && (
          <BATIcon
            name="close"
            color={colors.icon}
            size={12}
            style={[
              sharedStyles.padding,
              sharedStyles.borderRadiusHalf,
              styles.closeIcon,
              closeIconStyles,
              { zIndex: 1 },
            ]}
            onPress={handleOnDismiss}
          />
        )}
        <Dialog.Title style={customTitleStyles}>{header}</Dialog.Title>

        <Dialog.Content style={bodyStyles}>{body}</Dialog.Content>

        <Dialog.Actions style={customActionsStyles}>{footer}</Dialog.Actions>
        <BATConfetti />
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  dialog: {
    overflow: 'hidden',
    backgroundColor: colors.card_background_lighter,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.card_border_lighter,
  },
  closeIcon: {
    backgroundColor: colors.backgroundMedium,
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

export default BATDialog;
