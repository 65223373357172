import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { CardProps } from '../../../src/components/BATCard';
import { logoutUser } from '../../Authentication/slices/extraSlice';
import { minFieldPoints } from '../../Prizes/utils';
import {
  formatBadge,
  formatBadgesAboveTitle,
  formatDiscountProps,
} from '../../Prizes/slices/prizesSlice';
import { ARTICLE_TYPE } from '../../../models/Feed.model';
import { UserDataModel } from '../../../models/User.model';
import { TierReward } from '../../MembershipTiers/models';
import { getTimeDifference } from '../../Campaigns/utils';
import { formatDataByUserTierRewards, getUpperTiers } from '../../MembershipTiers/utils';

export enum EntityType {
  ARTICLES = 'articole',
  PRIZES = 'premii',
  PRODUCTS = 'produse',
  CAMPAIGNS = 'campanii',
}

export const formatDate = (dateString: string, comingSoon = false) => {
  if (comingSoon) {
    return `Disponibil din ${moment(dateString, 'YYYY/MM/DD', 'ro').format('DD MMM YYYY')}`;
  }

  const difMillisec = (new Date(dateString + 'T23:59:59') as any) - (new Date() as any);

  const minutes = Math.floor(difMillisec / (60 * 1000));
  const hours = Math.floor(difMillisec / (60 * 60 * 1000));
  const days = Math.floor(difMillisec / (24 * 60 * 60 * 1000));

  return `${days}d ${hours % 24}h ${minutes % 60}m`;
};

const isGuaranteedPrize = (entityData: any, prizeType?: string) => {
  return (
    prizeType === 'guaranteed' ||
    entityData?.type?.includes('Guaranteed') ||
    prizeType === 'bundle' ||
    entityData?.type?.includes('Bundle')
  );
};

const formatEntityData = (entityData: any, entityType: any, prizeType?: string) => {
  switch (entityType) {
    case EntityType.ARTICLES:
      return {
        nid: entityData.nid,
        image: entityData.field_image,
        category: entityData.field_content_territory || entityData.category,
        title: entityData.title,
        date: moment(entityData.created, 'DD/MM/YYYY ', 'ro').format('DD MMM YYYY'),
        brand: entityData.field_brand,
        authorName: entityData.field_author,
        authorImage: entityData.author_image,
        url_alias: `/articole${entityData?.brand_url_alias}${
          entityData?.article_type === ARTICLE_TYPE.product
            ? '/produs'
            : entityData?.territory_url_alias || entityData?.url_alias
        }${entityData?.url_alias}`,
      };
    case EntityType.PRIZES:
      const comingSoon = !entityData.status || entityData.status === 'future';
      const stock =
        ('field_stock' in entityData &&
          isGuaranteedPrize(entityData, prizeType) &&
          (entityData.field_stock === '' || Number(entityData.field_stock) === 0)) ||
        ('field_redeem_limit' in entityData &&
          entityData.field_redeem_limit !== '' &&
          Number(entityData.field_redeem_limit) === 0) ||
        (entityData.prizesAdvancedAccessRewardDays > 0 &&
          moment().startOf('day').isBefore(moment(entityData.field_start_date, 'YYYY-MM-DD')) &&
          'field_advance_access_limit' in entityData &&
          entityData.field_advance_access_limit !== '' &&
          Number(entityData.field_advance_access_limit) === 0) ||
        ('field_redeem_limit_reset' in entityData && entityData.field_redeem_limit_reset !== '')
          ? 0
          : Number(entityData.field_stock) > 0
          ? Number(entityData.field_stock)
          : undefined;

      return {
        nid: entityData.nid,
        image: entityData.field_image,
        title: entityData.title,
        brand: entityData.field_brand,
        activityPoints:
          'field_activity_points' in entityData &&
          Number(entityData.field_activity_points) > 0 &&
          !comingSoon
            ? Number(entityData.field_activity_points)
            : undefined,
        loyaltyPoints:
          'field_pack_points' in entityData &&
          Number(entityData.field_pack_points) > 0 &&
          !comingSoon
            ? Number(entityData.field_pack_points)
            : undefined,
        date: comingSoon ? formatDate(entityData.field_start_date_tier, true) : undefined,
        badge: {
          ...formatBadge(
            'chances' in entityData ? Number(entityData.chances) : undefined,
            stock,
            comingSoon,
          ),
        },
        stock,
        url_alias: comingSoon
          ? undefined
          : `/premiu/${
              entityData?.type?.includes('Limited')
                ? 'limitat'
                : entityData?.type?.includes('Guaranteed')
                ? 'garantat'
                : entityData?.type?.includes('Bundle')
                ? 'garantat/bundle'
                : 'moment'
            }${entityData.url_alias}`,
        isBundle: prizeType === 'bundle',
        displayPoints: entityData.displayPoints ?? 'default',
        ...formatDiscountProps(
          entityData,
          entityData.type?.toLowerCase(),
          entityData.tierResetDate,
        ),
        imageBottomLabel:
          comingSoon && entityData.prizesAdvancedAccessRewardDays
            ? {
                icon: 'sandtimer',
                label: `${entityData.prizesAdvancedAccessRewardDays} ${
                  entityData.prizesAdvancedAccessRewardDays == 1 ? 'zi' : 'zile'
                } de acces în avans`,
              }
            : 'field_redeem_limit_reset' in entityData &&
              entityData.field_redeem_limit_reset !== '' &&
              !(
                'field_stock' in entityData &&
                isGuaranteedPrize(entityData, prizeType) &&
                (entityData.field_stock === '' || Number(entityData.field_stock) === 0)
              ) &&
              !(
                'field_redeem_limit' in entityData &&
                entityData.field_redeem_limit !== '' &&
                Number(entityData.field_redeem_limit) === 0
              )
            ? {
                icon: 'sandtimer',
                label: `Revine din ${moment(
                  entityData.field_redeem_limit_reset,
                  'YYYY-MM-DD HH:mm:ss',
                ).format('DD MMM YYYY')}`,
              }
            : undefined,
        badgesAboveTitle: stock || !prizeType ? formatBadgesAboveTitle(entityData.tierNames) : [],
        exclusiveTierId: entityData.field_tier_exclusive_access,
      };
    case EntityType.PRODUCTS:
      return {
        nid: entityData.nid,
        image: entityData.field_image,
        category: entityData.field_product_range || entityData.category,
        title: entityData.title,
        brand: entityData.field_brand || entityData.parent_category,
        date: moment(entityData.created, 'DD/MM/YYYY ', 'ro').format('DD MMM YYYY'),
        url_alias: `/produse${entityData?.categorySlug}${entityData.url_alias}`,
      };
    case EntityType.CAMPAIGNS:
      let untilDate =
        'date' in entityData
          ? `${entityData.date.days}d ${entityData.date.hours}h ${entityData.date.minutes}m`
          : 'period' in entityData
          ? formatDate(entityData.period.end)
          : '';
      //Teaser
      if (untilDate === '' && 'campaign_start_date' in entityData) {
        const { days, hours, minutes } = getTimeDifference(entityData.campaign_start_date);
        untilDate = `${days}d ${hours}h ${minutes}m`;
      }
      return {
        nid: entityData.nid || entityData.id,
        image: entityData.field_image || entityData.header,
        title: entityData.title,
        date: untilDate,
        brand: entityData.field_brand || entityData.brand,
        url_alias: `/campanie${entityData.url_alias}`,
      };
    default:
      return {};
  }
};

function isCardProps(obj: any): obj is CardProps {
  const requiredKeys: (keyof CardProps)[] = ['nid', 'image', 'title', 'brand'];

  if (!obj || typeof obj !== 'object') {
    return false;
  }

  for (const key of requiredKeys) {
    if (!(key in obj)) {
      return false;
    }
  }

  return true;
}

const getInitialState = () => ({
  articole: [] as CardProps[],
  premii: [] as CardProps[],
  produse: [] as CardProps[],
  campanii: [] as CardProps[],
});

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: getInitialState(),
  reducers: {
    setFavorites: (
      state,
      action: {
        payload: {
          groupsOfFavorites: any;
          currentUser: UserDataModel | null;
          tierRewards: TierReward[];
          tierResetDate: string;
        };
      },
    ) => {
      const { groupsOfFavorites, currentUser, tierRewards, tierResetDate } = action.payload;

      state[EntityType.PRODUCTS] = [];
      if (groupsOfFavorites.product) {
        state[EntityType.PRODUCTS] =
          groupsOfFavorites.product?.map((product: any) =>
            formatEntityData(product, EntityType.PRODUCTS),
          ) || [];
      }

      state[EntityType.CAMPAIGNS] = [];
      if (groupsOfFavorites.campaign) {
        state[EntityType.CAMPAIGNS] =
          groupsOfFavorites.campaign.map((campaign: any) =>
            formatEntityData(campaign, EntityType.CAMPAIGNS),
          ) || [];
      }

      state[EntityType.ARTICLES] = [];
      if (groupsOfFavorites.article) {
        state[EntityType.ARTICLES] =
          groupsOfFavorites.article.map((article: any) =>
            formatEntityData(article, EntityType.ARTICLES),
          ) || [];
      }

      const allPrizes = [];

      if (groupsOfFavorites['limited prize']) {
        allPrizes.push(
          ...formatDataByUserTierRewards(
            groupsOfFavorites['limited prize'],
            tierRewards,
            currentUser,
          ).map((prize: any) => {
            const isExclusive = !!prize.field_tier_exclusive_access;
            return formatEntityData(
              {
                ...prize,
                tierNames: isExclusive
                  ? getUpperTiers(prize.field_tier_exclusive_access, tierRewards)
                  : prize.tierNames,
              },
              EntityType.PRIZES,
            );
          }),
        );
      }

      if (groupsOfFavorites['lucky Moments Campaign']) {
        allPrizes.push(
          ...formatDataByUserTierRewards(
            groupsOfFavorites['lucky Moments Campaign'],
            tierRewards,
            currentUser,
          ).map((prize: any) => {
            return formatEntityData(prize, EntityType.PRIZES);
          }),
        );
      }

      if (groupsOfFavorites['bundle']) {
        allPrizes.push(
          ...formatDataByUserTierRewards(groupsOfFavorites['bundle'], tierRewards, currentUser).map(
            (prize: any) => {
              const isExclusive = !!prize.field_tier_exclusive_access;
              return formatEntityData(
                {
                  ...prize,
                  ...minFieldPoints({ ...prize }),
                  tierResetDate: tierResetDate,
                  tierNames: isExclusive
                    ? getUpperTiers(prize.field_tier_exclusive_access, tierRewards)
                    : prize.tierNames,
                },
                EntityType.PRIZES,
                'bundle',
              );
            },
          ),
        );
      }

      if (groupsOfFavorites['guaranteed prize']) {
        allPrizes.push(
          ...formatDataByUserTierRewards(
            groupsOfFavorites['guaranteed prize'],
            tierRewards,
            currentUser,
          ).map((prize: any) => {
            const isExclusive = !!prize.field_tier_exclusive_access;

            return formatEntityData(
              {
                ...prize,
                tierResetDate: tierResetDate,
                tierNames: isExclusive
                  ? getUpperTiers(prize.field_tier_exclusive_access, tierRewards)
                  : prize.tierNames,
              },
              EntityType.PRIZES,
              'guaranteed',
            );
          }),
        );
      }

      state[EntityType.PRIZES] = allPrizes;
    },
    toggleFavorite: (state, action) => {
      const entityId = action.payload.entityId;
      const entityType = action.payload.entityType;
      const entityData = action.payload.entityData;
      const isFavorite = action.payload.isFavorite;

      if (isFavorite) {
        state[entityType as keyof typeof state] = state[entityType as keyof typeof state].filter(
          (item) => item?.nid?.toString() !== entityId.toString(),
        );
      } else {
        if (isCardProps(entityData)) {
          state[entityType as keyof typeof state].unshift(entityData);
        } else {
          state[entityType as keyof typeof state].unshift(
            formatEntityData(entityData, entityType) as CardProps,
          );
        }
      }
    },
    increaseChances: (state, action) => {
      const entityId = action.payload.entityId;
      const entityData = action.payload.entityData;

      const prize = state[EntityType.PRIZES].find((prize) => prize.nid === entityId);

      if (prize) {
        state[EntityType.PRIZES] = state[EntityType.PRIZES].filter((item) => item.nid !== entityId);
        state[EntityType.PRIZES].unshift(
          formatEntityData(entityData, EntityType.PRIZES) as CardProps,
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
  },
});

export const { setFavorites, toggleFavorite, increaseChances } = favoritesSlice.actions;
export default favoritesSlice.reducer;
