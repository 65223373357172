import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import { TierCondition, TierProgress, TierReward } from '../screens/MembershipTiers/models';

export const membershipTiersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTierRewards: builder.mutation<TierReward[], void>({
      query: () => ({
        url: `${Endpoints.getTierRewards}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getTierConditions: builder.mutation<TierCondition[], void>({
      query: () => ({
        url: `${Endpoints.getTierConditions}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getUserTierProgress: builder.mutation<TierProgress, void>({
      query: () => ({
        url: `${Endpoints.getUserTierProgress}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetTierRewardsMutation,
  useGetTierConditionsMutation,
  useGetUserTierProgressMutation,
} = membershipTiersApi;
