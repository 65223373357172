import { createSlice } from '@reduxjs/toolkit';
import { logoutUser } from '../../Authentication/slices/extraSlice';
import { TierCondition, TierReward, UserProgress } from '../models';
import api from '../../../config/api';

const getInitialState = () => ({
  tierRewards: [] as TierReward[],
  tierConditions: [] as TierCondition[],
  userProgress: {} as UserProgress,
});

const codeSlice = createSlice({
  name: 'tiers',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
    builder.addMatcher(api.endpoints.getTierRewards.matchFulfilled, (state, action) => {
      state.tierRewards = action.payload;
    });
    builder.addMatcher(api.endpoints.getTierConditions.matchFulfilled, (state, action) => {
      state.tierConditions = action.payload;
    });
    builder.addMatcher(api.endpoints.getUserTierProgress.matchFulfilled, (state, action) => {
      state.userProgress = action.payload;
    });
  },
});

export default codeSlice.reducer;
