import { BadgeIcons } from '../components/BadgeIcon';

export type Reward = {
  reward_id: string;
  reward_name: string;
  unit_measure: string;
  value: string;
  description: string;
};

export type TierReward = {
  tier_id: number;
  tier_name: string;
  tier_colour: string;
  tier_description: string;
  rewards: Reward[];
  discountDescription?: string;
};

export type Condition = {
  condition_id: string;
  condition_name: string;
  unit_measure: string;
  value: number | boolean;
  discountedValue?: number;
  icon?: string;
  userValue?: number | boolean;
  isExtraCondition: boolean;
  existsInPreviousTier?: boolean;
  description: string;
  earnedDate?: string;
  onPress?: (buttonLabel?: string) => void;
  buttonLabel: string;
  mandatory: boolean;
};

export type TierCondition = {
  tier_id: number;
  tier_name: string;
  conditions: Condition[];
};

export type TierProgress = {
  volume_condition: number;
  pack_codes_condition: number;
  new_category_condition: number;
  content_engagement_condition: number;
  completed_profile_condition: boolean;
  taste_profile_condition: boolean;
  pc_vuse_velo_condition: boolean;
  device_registered_condition: boolean;
  user_satisfaction_condition: boolean;
  glo_subscription_condition: boolean;
};

export interface TiersWithCondition extends TierReward {
  pointsRequirments: Condition[];
  validPointsRequirements: number;
  extraRequirments: Condition[];
  validExtraRequirements: number;
  isPromotion?: boolean;
}

export interface CurrentTier {
  tier: TiersWithCondition;
  tiers: TiersWithCondition[];
  resetDate: string;
}

export interface UserProgress {
  completed_profile_condition: boolean;
  content_engagement_condition: number;
  device_registered_condition: boolean;
  glo_subscription_condition: boolean;
  new_category_condition: number;
  pack_codes_condition: number;
  pc_vuse_velo_condition: boolean;
  taste_profile_condition: boolean;
  user_satisfaction_condition: boolean;
  volume_condition: number;
}

export enum ChallengeBadgeShapes {
  Circular = 'circular',
  Hexagonal = 'hexagonal',
  Square = 'square',
}

export type Challenge = {
  title: string;
  description?: string;
  shape: ChallengeBadgeShapes;
  icon: string;
  color: string;
  url_alias: string;
  type?: 'progress' | 'streak';
  currentValue?: number;
  maxValue?: number;
  alert?: {
    type: 'warning' | 'danger';
    message: string;
  };
  earnedDate?: string;
  earnedMessage?: string;
  disabled?: boolean;
  level?: string;
};

export type ChallengeList = Record<string, Challenge[]>;

export enum BadgeTypes {
  Circular = 'circular',
  Octagonal = 'octagonal',
  Hexagonal = 'hexagonal',
  Square = 'square',
}

export enum BadgeStates {
  NotStarted = 'not-started',
  Started = 'started',
  Achieved = 'achieved',
}

export interface BadgeLevel {
  description: string;
  state: BadgeStates;
  levelEarnedTimestamp?: number;
  currentProgress?: number;
  targetProgress?: number;
  rewardActivityPoints: number;
  rewardLoyaltyPoints: number;
  rarity: number;
}

export type BadgeCategory = 'codes' | 'articles-stories' | 'device-subscription';

export interface BadgeData {
  category: BadgeCategory;
  title: string;
  urlAlias: string;
  type: BadgeTypes;
  icon: BadgeIcons;
  levels: BadgeLevel[];
}
