import { BadgeData, BadgeStates, BadgeTypes } from '../models';
import { BadgeIcons } from '../components/BadgeIcon';

export const faqQuestions = [
  {
    question: 'Ce este programul de beneficii din cadrul OneUp?',
    answer: [
      'Am adus o nouă inițiativă în cadrul programului de loialitate care îți răsplătește fidelitatea prin beneficii exclusive, reduceri și alte recompense. Cu cât interacționezi mai mult cu platforma, cu atât urci în nivel și primești avantaje mai mari.',
    ],
  },
  {
    question: 'Ce este un nivel?',
    answer: [
      'Un nivel reprezintă un statut în programul de loialitate, determinat de activitatea și punctele acumulate. Există 5 niveluri: Bronze, Silver, Gold, Platinum și Diamond, fiecare oferind beneficii progresive.',
    ],
  },
  {
    question: 'Ce sunt beneficiile?',
    answer: [
      'Beneficiile sunt avantaje oferite în funcție de nivelul tău. Acestea pot include bonusuri lunare, reduceri exclusive, acces în avans la anumite premii sau la oferte speciale. Lista completă a beneficiilor este disponibilă în secțiunea Compară Beneficiile.',
    ],
  },
  {
    question: 'Când se acordă beneficiile?',
    answer: [
      'Beneficiile devin active la intervale predeterminate de 30 de zile, după îndeplinirea condițiilor de avansare într-un nou nivel. Vei fi anunțat în pagina principală de Progres și Beneficii cu privire la data de la care te poți bucura de beneficiile unui nou nivel. Excepție de la această regulă face avansare în orice moment în nivelul Silver, pentru care beneficiile sunt acordate imediat.',
    ],
  },
  {
    question: 'Cât timp sunt menținute beneficiile?',
    answer: [
      'Beneficiile rămân disponibile atâta timp cât faci parte din programul de loialitate și îți menții unul dintre cele 5 niveluri. Acestea vor fi diferite în funcție de nivelul la care te afli.',
    ],
  },
  {
    question: 'Când avansez într-un nou nivel?',
    answer: [
      'Avansarea se face atunci când îndeplinești criteriile necesare pentru următorul nivel. Există 3 categorii de criterii: 1. Activitatea din cele mai recente 180 de zile, care include acumularea de puncte de loialitate și de activitate, 2. Activitatea din cele mai recente 30 de zile, care include introducerea de coduri și 3. Acțiuni specifice menționate la fiecare nivel, care pot fi efectuate o singură dată sau recurent, la anumite intervale de timp. Poți verifica progresul tău în secțiunea Progres.',
    ],
  },
  {
    question: 'Cum verific nivelul și progresul meu?',
    answer: [
      'Poți verifica oricând nivelul tău, cerințe îndeplinite și progresul către următorul nivel în secțiunea Nivelul si beneficiile mele și Progresul meu.',
    ],
  },
  {
    question: 'Când revin la un nivel anterior?',
    answer: [
      'Dacă nu îndeplinești criteriile de menținere pentru nivelul tău în perioada stabilită, vei fi retrogradat la nivelul corespunzător activității tale recente. Vei primi atenționări în secțiunea „Progres” înainte de orice schimbare de nivel.',
    ],
  },
  {
    question: 'Cum mă mențin la nivelul curent?',
    answer: [
      'Pentru a rămâne la nivelul tău actual, trebuie să continui să îndeplinești cerințele specifice acestuia.',
    ],
  },
  {
    question: 'Cum pot avansa mai repede în nivel?',
    answer: [
      'Pentru a avansa mai repede, poți îndeplini cerințele necesare pentru următorul nivel într-un ritm mai alert. Cu toate acestea, te rugăm să ții cont că este permisă avansarea la un nou nivel o singură dată în termen de 30 de zile. Excepție face perioada 31.03 - 28.06.2025, când poți avansa la nivelul Silver imediat ce ai îndeplinit criteriile. În plus, beneficiezi de oferta de lansare în această perioadă.',
    ],
  },
  {
    question: 'Care este mecanismul de menținere / retrogradare / avansare în niveluri?',
    answer: [
      'Activitatea ta în cadrul programului de loialitate este înregistrată pentru cele mai recente 180 de zile pentru acumularea punctelor de loialitate și de activitate. Pe măsură ce avansăm în timp, la fiecare 30 de zile, actualizăm numărul de puncte de loialitate și de activitate pe care trebuie să le dobândești pentru a respecta perioada de cele mai recente 180 de zile menționată anterior. De asemenea, la fiecare 30 de zile trebuiesc îndeplinite acțiunile privind introducerea numărului de coduri minim pentru nivelul respectiv. La final, există acțiuni specifice menționate la fiecare nivel, care pot fi efectuate o singură dată sau recurent, la anumite intervale de timp, atunci când devin disponibile.',
    ],
  },
  {
    question: 'Ce se întâmplă cu punctele și codurile dacă sunt retrogradat sau avansat?',
    answer: [
      'Punctele și codurile au scop statistic. Acestea nu reprezintă balanța de puncte din Portofel, ci sunt doar o modalitate de a înregistra progresul în cadrul nivelurilor.',
    ],
  },
  {
    question: 'Pot transfera nivelul beneficiile mele altcuiva?',
    answer: ['Nivelul și beneficiile sunt nominale și nu pot fi transferate altor utilizatori.'],
  },
  {
    question: 'Cum mă înscriu în programul de beneficii?',
    answer: [
      'Ești înscris automat atunci când îți creezi un cont pe platforma noastră. Nu este necesară o înregistrare separată.',
    ],
  },
  {
    question: 'Ce se întâmplă dacă nu mai folosesc contul pentru o perioadă lungă?',
    answer: [
      'Dacă nu efectuezi nicio activitate din cele menționate în nivel, nivelul tău va fi resetat conform cerințelor pe care le îndeplinești.',
    ],
  },
  {
    question: 'Programul de beneficii are o dată de expirare?',
    answer: [
      'Nu, programul de beneficii este activ pe termen nelimitat, dar cerințele și beneficiile pot fi ajustate în timp. Vei fi notificat despre orice schimbare importantă în OneUp.',
    ],
  },
];

export const badges: BadgeData[] = [
  {
    category: 'codes',
    title: 'I like bonuses',
    urlAlias: '/i-like-bonuses',
    type: BadgeTypes.Circular,
    icon: BadgeIcons.TreasureChest,
    levels: [
      {
        description: 'Enter 1 bonus code from any channel or campaign',
        state: BadgeStates.Achieved,
        levelEarnedTimestamp: 1748149336,
        currentProgress: undefined,
        targetProgress: undefined,
        rewardActivityPoints: 1,
        rewardLoyaltyPoints: 2,
        rarity: 75,
      },
      {
        description: 'Enter 10 bonus codes from any channel or campaign',
        state: BadgeStates.Started,
        levelEarnedTimestamp: undefined,
        currentProgress: 9,
        targetProgress: 10,
        rewardActivityPoints: 2,
        rewardLoyaltyPoints: 4,
        rarity: 50,
      },
      {
        description: 'Enter 50 bonus codes from any channel or campaign',
        state: BadgeStates.NotStarted,
        levelEarnedTimestamp: undefined,
        currentProgress: 9,
        targetProgress: 50,
        rewardActivityPoints: 4,
        rewardLoyaltyPoints: 8,
        rarity: 15,
      },
    ],
  },
  {
    category: 'codes',
    title: 'Avid reader',
    urlAlias: '/avid-reader',
    type: BadgeTypes.Circular,
    icon: BadgeIcons.Cup,
    levels: [
      {
        description: 'Avid reader...',
        state: BadgeStates.Achieved,
        levelEarnedTimestamp: 1748149336,
        currentProgress: undefined,
        targetProgress: undefined,
        rewardActivityPoints: 1,
        rewardLoyaltyPoints: 5,
        rarity: 25,
      },
    ],
  },
  {
    category: 'codes',
    title: 'Work days on duty',
    urlAlias: '/work-days-on-duty',
    type: BadgeTypes.Circular,
    icon: BadgeIcons.Briefcase,
    levels: [
      {
        description: 'Work days on duty 1...',
        state: BadgeStates.NotStarted,
        levelEarnedTimestamp: undefined,
        currentProgress: 9,
        targetProgress: 10,
        rewardActivityPoints: 1,
        rewardLoyaltyPoints: 5,
        rarity: 60,
      },
    ],
  },
  {
    category: 'codes',
    title: 'The chosen one',
    urlAlias: '/the-chosen-one',
    type: BadgeTypes.Circular,
    icon: BadgeIcons.Crown,
    levels: [
      {
        description: 'The chosen one 1...',
        state: BadgeStates.Achieved,
        levelEarnedTimestamp: 1748149336,
        currentProgress: undefined,
        targetProgress: undefined,
        rewardActivityPoints: 1,
        rewardLoyaltyPoints: 2,
        rarity: 65,
      },
      {
        description: 'The chosen one 2...',
        state: BadgeStates.Started,
        levelEarnedTimestamp: undefined,
        currentProgress: 9,
        targetProgress: 10,
        rewardActivityPoints: 5,
        rewardLoyaltyPoints: 10,
        rarity: 40,
      },
      {
        description: 'The chosen one 3...',
        state: BadgeStates.NotStarted,
        levelEarnedTimestamp: undefined,
        currentProgress: 9,
        targetProgress: 50,
        rewardActivityPoints: 10,
        rewardLoyaltyPoints: 20,
        rarity: 2,
      },
    ],
  },
  {
    category: 'articles-stories',
    title: 'Influencer',
    urlAlias: '/influencer',
    type: BadgeTypes.Circular,
    icon: BadgeIcons.Megaphone,
    levels: [
      {
        description: 'Influencer 1...',
        state: BadgeStates.Achieved,
        levelEarnedTimestamp: 1748149336,
        currentProgress: undefined,
        targetProgress: undefined,
        rewardActivityPoints: 1,
        rewardLoyaltyPoints: 2,
        rarity: 65,
      },
      {
        description: 'Influencer 2...',
        state: BadgeStates.Started,
        levelEarnedTimestamp: undefined,
        currentProgress: 1,
        targetProgress: 20,
        rewardActivityPoints: 5,
        rewardLoyaltyPoints: 10,
        rarity: 10,
      },
      {
        description: 'Influencer 3...',
        state: BadgeStates.NotStarted,
        levelEarnedTimestamp: undefined,
        currentProgress: 1,
        targetProgress: 50,
        rewardActivityPoints: 10,
        rewardLoyaltyPoints: 20,
        rarity: 3,
      },
      {
        description: 'Influencer 3...',
        state: BadgeStates.NotStarted,
        levelEarnedTimestamp: undefined,
        currentProgress: 1,
        targetProgress: 100,
        rewardActivityPoints: 20,
        rewardLoyaltyPoints: 40,
        rarity: 1,
      },
    ],
  },
  {
    category: 'articles-stories',
    title: 'I know the answer',
    urlAlias: '/i-know-the-answer',
    type: BadgeTypes.Circular,
    icon: BadgeIcons.Bulb,
    levels: [
      {
        description: 'I know the answer 1...',
        state: BadgeStates.Achieved,
        levelEarnedTimestamp: 1748138336,
        currentProgress: undefined,
        targetProgress: undefined,
        rewardActivityPoints: 1,
        rewardLoyaltyPoints: 5,
        rarity: 60,
      },
    ],
  },
];

export const tiersPersonalizations = [
  {
    name: 'Bronze',
    personalization: require('../../../assets/images/membership-tiers/Mask_1.png'),
    icon: require('../../../assets/images/membership-tiers/Icon_1.png'),
  },
  {
    name: 'Silver',
    personalization: require('../../../assets/images/membership-tiers/Mask_2.png'),
    icon: require('../../../assets/images/membership-tiers/Icon_2.png'),
  },
  {
    name: 'Gold',
    personalization: require('../../../assets/images/membership-tiers/Mask_3.png'),
    icon: require('../../../assets/images/membership-tiers/Icon_3.png'),
  },
  {
    name: 'Platinum',
    personalization: require('../../../assets/images/membership-tiers/Mask_4.png'),
    icon: require('../../../assets/images/membership-tiers/Icon_4.png'),
  },
  {
    name: 'Diamond',
    personalization: require('../../../assets/images/membership-tiers/Mask_5.png'),
    icon: require('../../../assets/images/membership-tiers/Icon_5.png'),
  },
];
