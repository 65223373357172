import lottie, { AnimationItem } from 'lottie-web';
import { View } from 'react-native';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  changeType,
  closeConfetti,
  confettiType,
  selectIsConfettiOpen,
} from '../../slices/confettiSlice';
import screenAnimation from '../../../assets/confetti.json';

interface BATConfettiProviderProps {
  children?: React.ReactNode;
}

export const BATConfetti = ({ children }: BATConfettiProviderProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsConfettiOpen);
  const type = useSelector(confettiType);

  const animationContainer = useRef(null);
  const confettiDetailsByType = () => {
    switch (type) {
      case 'screen':
        return screenAnimation;
      case 'modal':
        return screenAnimation;
      default:
        return null;
    }
  };

  useEffect(() => {
    let animationInstance: AnimationItem | undefined;
    if (animationContainer.current && isOpen) {
      animationInstance = lottie.loadAnimation({
        container: animationContainer.current,
        animationData: confettiDetailsByType(),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    }

    return () => {
      if (animationInstance) {
        animationInstance.destroy();
      }
    };
  }, [isOpen, type]);

  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => {
        dispatch(closeConfetti());
        dispatch(changeType('screen'));
      }, 10000);
      return () => clearTimeout(timeout);
    }

    return undefined;
  }, [isOpen, dispatch]);

  if (!isOpen) return children;

  return (
    <>
      {children}
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 100000,
          pointerEvents: 'none',
          width: '100%',
          height: '100%',
        }}>
        <View
          ref={animationContainer}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </View>
    </>
  );
};
