import api from '../../../config/api';

import { createSlice } from '@reduxjs/toolkit';
import { logoutUser } from '../../Authentication/slices/extraSlice';

const getInitialState = () => ({
  unread: 0,
  revalidateData: false,
});

const slice = createSlice({
  name: 'notification',
  initialState: getInitialState(),
  reducers: {
    setRevalidateData: (state, action) => {
      state.revalidateData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
    builder.addMatcher(api.endpoints.getNotificationList.matchFulfilled, (state, { payload }) => {
      state.unread = payload.notifications.total.unread;
    });
  },
});

export const { setRevalidateData } = slice.actions;
export default slice.reducer;
